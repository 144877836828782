<template>
    <div class="results-page">
        <PageHeading :breadcrumbs="breadcrumbs" title="Results" />

        <p class="paragraph">Next, we need to know all the possible outcomes of your quiz.</p>

        <Accordion
            v-if="convoDetailsView.resultsView.resultItems.length > 0"
            :items="convoDetailsView.resultsView.resultItems"
            :is-draggable="false"
            @headerOnClick="resultItemHeaderOnClick"
            @deleteOnClick="deleteOnClick"
            @propertyOnChange="setResultItem"
            @undoDeletedItemOnClick="undoDeletedItemOnClick"
        />

        <ErrorMessage v-if="convoDetailsView.resultsView.errors.length > 0">
            {{ convoDetailsView.resultsView.errors[0] }}
        </ErrorMessage>

        <div class="align-center m-b-2">
            <TextButton
                v-if="canAddMoreResults"
                class="m-b-1"
                icon="plus-2"
                @click="addResultOnClick"
            >
                Add more results
            </TextButton>
        </div>

        <Stepper
            show-next-button
            next-button-help="Click 'save results' to go to messages"
            next-button-text="Save results"
            :nav-is-opened="navIsOpened"
            :is-loading="$wait.is(LoadingFlag.MessagesSave)"
            @backButtonOnClick="backButtonClick($event)"
            @nextButtonOnClick="nextButtonClick($event)"
        />
        <Dialog
            show-confirm-button
            confirm-button-text="Discard changes"
            close-button-text="Continue Editing"
            :is-dialog-visible="leaveDialogIsOpen"
            @onClose="leaveDialogOnConfirm"
            @onClickOutside="leaveDialogOnConfirm"
            @closeOnEscapeEvent="leaveDialogOnConfirm"
            @confirmButtonOnClick="leaveDialogOnClose"
        >
            <template #header> There are unsaved changes made to the convo. </template>
            <template #body> Continue editing to save progress. </template>
        </Dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import TextButton from '@/components/ui/TextButton';
import Stepper from '@/components/ui/Stepper';
import ErrorMessage from '@/components/ui/ErrorMessage';
import Accordion from '@/components/ui/Accordion';
import convoTypes from '../json/convoTypes';
import { ConvoTypes, LoadingFlag, ButtonStyle } from '@/store/enums';
import Dialog from '@/components/ui/Dialog';
import LeavingDialogMixin from '@/mixins/leavingDialogMixin';
import PageHeading from '@/components/ui/PageHeading';

export default {
    name: 'Results',
    components: {
        TextButton,
        Stepper,
        ErrorMessage,
        PageHeading,
        Accordion,
        Dialog,
    },
    mixins: [LeavingDialogMixin],
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            LoadingFlag,
            ButtonStyle,
            breadcrumbs: [],
        };
    },
    computed: {
        ...mapGetters('main', {
            isLoading: 'isLoading',
        }),
        ...mapGetters('channel', {
            channelDetails: 'channelDetails',
        }),
        ...mapGetters('convoDetails', {
            isNewConvo: 'isNewConvo',
            convoDetailsView: 'convoDetailsView',
            areResultItemsValid: 'areResultItemsValid',
            messagesModelHasChanges: 'messagesModelHasChanges',
            resultsModelHasChanges: 'resultsModelHasChanges',
        }),
        canAddMoreResults() {
            // todo: temporary, in the future it'll be possible to add more than 1 result
            return (
                this.convoDetailsView.convo.type === ConvoTypes.PersonalityQuiz ||
                (this.convoDetailsView.convo.type === ConvoTypes.TriviaQuiz &&
                    this.convoDetailsView.resultsView.resultItems.length < 1)
            );
        },
        convoType() {
            const convoType = convoTypes.find((t) => t.name === this.convoDetailsView.convo.type);
            if (convoType) {
                return convoType.title;
            }
            return 'Convo';
        },
        areResultsValid() {
            return this.areResultItemsValid;
        },
        hasUnsavedChanges() {
            return this.messagesModelHasChanges || this.resultsModelHasChanges;
        },
    },
    async created() {
        await this.setChannelDetails();
        if (!this.isNewConvo) {
            await this.loadConvo({
                id: this.$route.params.convoId,
                channelCode: this.channelDetails.channel.code,
            });
        }

        this.setBreadcrumbs();
    },
    methods: {
        ...mapActions('convoDetails', {
            toggleResultItem: 'toggleResultItem',
            setResultItem: 'setResultItem',
            validateResult: 'validateResult',
            addResultItem: 'addResultItem',
            deleteResult: 'deleteResult',
            undoDeletedResultItem: 'undoDeletedResultItem',
            loadConvo: 'loadConvo',
            resetConvoDetailsState: 'resetConvoDetailsState',
            saveConvoMessages: 'saveConvoMessages',
        }),
        ...mapActions('channel', {
            setChannelDetails: 'setChannelDetails',
        }),
        async addResultOnClick(event) {
            await this.validateResult();
            this.addResultItem();
        },
        resultItemHeaderOnClick(event, path) {
            this.toggleResultItem(path);
        },
        deleteOnClick(event, path, parentPath) {
            this.deleteResult({ path, parentPath });
        },
        backButtonClick(event) {
            this.$router.push({ name: 'name-convo' });
        },
        async nextButtonClick(event) {
            await this.validateResult();
            if (this.areResultsValid) {
                await this.saveConvoMessages();
                this.$router.push({
                    name: 'messages',
                    params: {
                        channelId: this.$route.params.channelId,
                        convoId: this.$route.params.convoId,
                    },
                });
            }
        },
        undoDeletedItemOnClick(event, path) {
            this.undoDeletedResultItem(path);
        },
        setBreadcrumbs() {
            const breadcrumbs = [];

            const channelsLink = { name: 'all-channels' };
            breadcrumbs.push(new Breadcrumb('channels', channelsLink));

            let convoLink;
            if (this.channelDetails.channel.id) {
                convoLink = {
                    name: 'convo-list',
                    params: { channelId: this.channelDetails.channel.id },
                };
            }
            breadcrumbs.push(new Breadcrumb(this.channelDetails.channel.name, convoLink));
            breadcrumbs.push(
                new Breadcrumb(`${this.convoType} CONVO: ${this.convoDetailsView.convo.name}`, {
                    name: 'messages',
                    channelCode: this.channelDetails.channel.code,
                    convoId: this.convoDetailsView.convo.id,
                }),
            );
            breadcrumbs.push(new Breadcrumb('Results'));
            this.breadcrumbs = breadcrumbs;
        },
    },
    async beforeRouteLeave(to, from, next) {
        if (!this.$route.meta.group || to.meta.group !== this.$route.meta.group) {
            this.resetConvoDetailsState();
        }

        next();
    },
};
</script>
